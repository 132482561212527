export const checkMedicalSummary = async (apiUrl, aptId, token) => {
  const response = await fetch(
    `${apiUrl}/api/v1/check-medical-summary/${aptId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  );
  return response;
};

export const generateSummaryApi = async (apiUrl, aptId, token) => {
  const response = await fetch(`${apiUrl}/get-medical-summary/${aptId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
  return response;
};

export const saveSummaryApi = async (apiUrl, aptId, token, aptSummary) => {
  const response = await fetch(
    `${apiUrl}/api/v1/update-medical-summary/${aptId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(aptSummary),
      credentials: "include",
    }
  );

  return response;
};
