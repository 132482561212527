import React, { useEffect, useState, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import TextField from "@mui/material/TextField";
import { useParams, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  useMediaQuery,
  Switch,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StandardButton from "./StyledComponents/StandardButton";
import useTokenRedirect from "../utils/authUtils";
import { getSummaryWithDiagnosis } from "../api/getSummaryWithDiagnosis";
import { updateSummaryWithDiagnosis } from "../api/updateSummaryWithDiagnosis";
import { emailSummaryWithDiagnosis } from "../api/emailSummaryWithDiagnosis";
import { toggleSummaryVisibility } from "../api/toggleSummaryVisibility";
import { treatmentRatingApi } from "../api/ratingsApi";

export default function SummaryWithDiagnosis({
  theme,
  showSummaryToOwner,
  calendlyEvent,
  showRating,
  selectedRating,
  setSelectedRating
}) {
  const [summaryData, setSummaryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showSummary, setShowSummary] = useState(showSummaryToOwner);
  const { getTokenOrRedirect } = useTokenRedirect();
  const token = getTokenOrRedirect();
  const params = useParams();
  const aptId = params.id;

  function getUserFromToken() {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  }
  const userData = getUserFromToken();
  const userType = userData.sub.user_type;
  console.log(userType);

  const fetchSummary = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getSummaryWithDiagnosis(apiUrl, aptId, token);
      setSummaryData(data);
      setEditableData(data);
    } catch (error) {
      console.error("Error fetching the summary:", error);
      if (error.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  }, [apiUrl, aptId, token, navigate]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const handleChange = (field, value) => {
    setEditableData({
      ...editableData,
      [field]: value,
    });
  };

  const handleSave = async () => {
    try {
      const data = await updateSummaryWithDiagnosis(
        apiUrl,
        aptId,
        token,
        editableData
      );
      setSummaryData(editableData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating the summary:", error);
    }
  };

  const handleEmailReport = async () => {
    try {
      await emailSummaryWithDiagnosis(apiUrl, aptId, token, email);
      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email. Please try again.");
    }
  };

  const formatSummary = (summary) => {
    // Remove ** from category headers
    let formattedSummary = summary
      ?.replace(/\*\*([^*]+)\:\*\*/g, "$1:") // Remove ** from headers
      .replace(/\*\*/g, "") // Remove any remaining **
      .replace(/\*\*([^*]+)\:\*\*\n/g, "$1:\n"); // Ensure category names are followed by a colon and newline

    // Add indentation to list items
    formattedSummary = formattedSummary?.replace(
      /- /g,
      "&nbsp;&nbsp;&nbsp;&nbsp;- "
    ); // Add indentation before each list item

    return formattedSummary;
  };

  const formattedSummary = formatSummary(summaryData?.summary);

  const handleToggle = async () => {
    setLoading(true);
    try {
      const data = await toggleSummaryVisibility(apiUrl, aptId);
      setShowSummary(data.show_summary_to_owner);
    } catch (error) {
      console.error("Error updating summary status:", error);
    } finally {
      setLoading(false);
    }
  };

  const addNewDiagnosis = () => {
    const updatedDiagnoses = [
      { diagnosis: "", justification: "", treatment: "" },
      ...editableData.diagnoses_with_treatment,
    ];
    setEditableData({
      ...editableData,
      diagnoses_with_treatment: updatedDiagnoses,
    });
  };

  const handleRatingClick = async (rating) => {
    setSelectedRating(rating);
    const ratingValue = rating;

    try {
      const response = await treatmentRatingApi(apiUrl, aptId, ratingValue)
      if (response.ok) {
        const data = await response.json();
        console.log("response data", data)
        console.log("Rating submitted successfully:", data);
      } else {
        const errorData = await response.json();
        console.error("Failed to submit rating:", errorData);
      }
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!summaryData) {
    return (
      <Typography variant="h6" color="error" align="center">
        No data available
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        boxShadow: "0 1px 3px rgba(0, 0, 0, 1)",
        borderRadius: "10px",
        padding: isMobile ? 2 : 3,
        backgroundColor: "#F9F9F9",
        maxHeight: "100%",
        overflow: "auto",
        mt: userType === "vet" ? 1 : 4,
        marginRight: 1,
        marginLeft: 1,
      }}
    >
      {userType === "vet" && (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            p: isMobile ? 0 : 1,
            gap: 1,
          }}
        >
                              <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column", // Stack fields vertically
                        gap: 2, // Add space between text fields
                      }}
                    >
          {showRating && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography variant="body1" style={{ marginRight: "10px" }}>
                Rate the Quality of the Response:
              </Typography>
              <IconButton
                onClick={() => handleRatingClick("up")}
                sx={{
                  color: selectedRating === "up" ? "#4CAF50" : "#9E9E9E",
                }}
              >
                <ThumbUpAltIcon fontSize="large" />
              </IconButton>
              <IconButton
                onClick={() => handleRatingClick("down")}
                sx={{
                  color: selectedRating === "down" ? "#F44336" : "#9E9E9E",
                }}
              >
                <ThumbDownAltIcon fontSize="large" />
              </IconButton>
            </div>
          )}
          <StandardButton
            sx={{ width: "20%" }} 
            text={isEditing ? "Cancel" : "Edit"}
            handleClick={() => setIsEditing(!isEditing)}
          />
          </Box>
          {!isEditing && calendlyEvent !== "None" && (
            <FormControlLabel
              sx={{ ml: 2 }}
              control={
                <Switch
                  checked={showSummary}
                  onChange={handleToggle}
                  color={showSummary ? "secondary" : "primary"}
                />
              }
              label={
                showSummary
                  ? "Hide Summary from Client"
                  : "Show Summary to Client"
              }
            />
          )}
        </Box>
      )}
      <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
        <strong>Medical Record</strong>
      </Typography>
      <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
        Created by:{" "}
        {summaryData.veterinarian_first_name +
          " " +
          summaryData.veterinarian_last_name}
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Typography variant={isMobile ? "body" : "h6"} gutterBottom>
          Pet Information
        </Typography>
        {isEditing ? (
          <>
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              value={editableData.pet_name}
              onChange={(e) => handleChange("pet_name", e.target.value)}
            />
            <TextField
              label="Breed"
              fullWidth
              margin="normal"
              value={editableData.pet_breed}
              onChange={(e) => handleChange("pet_breed", e.target.value)}
            />
            <TextField
              label="Sex"
              fullWidth
              margin="normal"
              value={editableData.pet_sex}
              onChange={(e) => handleChange("pet_sex", e.target.value)}
            />
          </>
        ) : (
          <>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              marginTop={isMobile ? "10px" : "0px"}
            >
              <strong>Name:</strong> {summaryData.pet_name}
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"}>
              <strong>Breed:</strong> {summaryData.pet_breed}
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"}>
              <strong>Sex:</strong> {summaryData.pet_sex}
            </Typography>
          </>
        )}
      </Box>
      {/* Owner Information */}
      <Box sx={{ mt: 4 }}>
        <Typography variant={isMobile ? "body" : "h6"} gutterBottom>
          Owner Information
        </Typography>
        {isEditing ? (
          <>
            <TextField
              label="Owner First Name"
              fullWidth
              margin="normal"
              value={editableData.owner_first_name}
              onChange={(e) => handleChange("owner_first_name", e.target.value)}
            />
            <TextField
              label="Owner Last Name"
              fullWidth
              margin="normal"
              value={editableData.owner_last_name}
              onChange={(e) => handleChange("owner_last_name", e.target.value)}
            />
            <TextField
              label="Owner Email"
              fullWidth
              margin="normal"
              value={editableData.owner_email}
              onChange={(e) => handleChange("owner_email", e.target.value)}
            />
          </>
        ) : (
          <>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              marginTop={isMobile ? "5px" : "0px"}
              fullWidth
            >
              <strong>Name:</strong>{" "}
              {summaryData.owner_first_name + " " + summaryData.owner_last_name}
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"} fullWidth>
              <strong>Email:</strong> {summaryData.owner_email}
            </Typography>
          </>
        )}
      </Box>
      {/* Appointment Details */}
      <Box sx={{ mt: 4 }}>
        <Typography variant={isMobile ? "body" : "h6"} gutterBottom>
          Appointment Details
        </Typography>
        {isEditing ? (
          <>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              marginTop={isMobile ? "5px" : "0px"}
            >
              <strong>Date:</strong> {summaryData.appointment_date}
            </Typography>
            <TextField
              label="Appointment Reason"
              fullWidth
              multiline
              margin="normal"
              value={editableData.appointment_reason}
              onChange={(e) =>
                handleChange("appointment_reason", e.target.value)
              }
            />
          </>
        ) : (
          <>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              marginTop={isMobile ? "5px" : "0px"}
            >
              <strong>Date:</strong> {summaryData.appointment_date}
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"}>
              <strong>Reason:</strong> {summaryData.appointment_reason}
            </Typography>
          </>
        )}
      </Box>
      {/* Patient Summary */}
      <Box sx={{ mt: 4 }}>
        <Typography variant={isMobile ? "body" : "h6"} gutterBottom>
          Appointment Summary
        </Typography>
        {isEditing ? (
          <>
            <TextField
              label="Summary"
              fullWidth
              multiline
              margin="normal"
              value={editableData.summary}
              onChange={(e) => handleChange("summary", e.target.value)}
            />
          </>
        ) : (
          <>
            <Typography
              variant="body2"
              sx={{ mb: 1, color: "#646464" }}
              component="div"
              dangerouslySetInnerHTML={{
                __html: formattedSummary?.replace(/\n/g, "<br />"),
              }}
            />
          </>
        )}
      </Box>
      {/* Selected Diagnoses */}
      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant={isMobile ? "body" : "h6"}
            sx={{ paddingTop: 1 }}
            gutterBottom
          >
            Selected Diagnoses
          </Typography>
          {isEditing && (
            <IconButton
              onClick={addNewDiagnosis}
              sx={{
                ml: 1,
                backgroundColor: "#0FBEB7",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#757575",
                },
              }}
            >
              <AddIcon />
            </IconButton>
          )}
        </Box>
        {editableData?.diagnoses_with_treatment?.length > 0 ? (
          <List>
            {editableData.diagnoses_with_treatment.map((diag, index) => (
              <ListItem
                key={index}
                sx={{
                  width: "100%",
                  display: "block",
                }}
              >
                {isEditing ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column", // Stack fields vertically
                        gap: 2, // Add space between text fields
                      }}
                    >
                      <TextField
                        label="Diagnosis"
                        fullwidth
                        sx={{
                          width: isMobile ? "100%" : "70%",
                        }}
                        multiline
                        margin="normal"
                        value={diag.diagnosis}
                        onChange={(e) => {
                          const updatedDiagnoses = [
                            ...editableData.diagnoses_with_treatment,
                          ];
                          updatedDiagnoses[index].diagnosis = e.target.value;
                          setEditableData({
                            ...editableData,
                            diagnoses_with_treatment: updatedDiagnoses,
                          });
                        }}
                      />
                      <TextField
                        label="Justification"
                        fullwidth
                        sx={{
                          width: isMobile ? "100%" : "70%",
                        }}
                        multiline
                        margin="normal"
                        value={diag.justification}
                        onChange={(e) => {
                          const updatedDiagnoses = [
                            ...editableData.diagnoses_with_treatment,
                          ];
                          updatedDiagnoses[index].justification =
                            e.target.value;
                          setEditableData({
                            ...editableData,
                            diagnoses_with_treatment: updatedDiagnoses,
                          });
                        }}
                      />
                      <TextField
                        label="Treatment"
                        fullwidth
                        multiline
                        margin="normal"
                        sx={{
                          width: isMobile ? "100%" : "70%",
                        }}
                        value={diag.treatment}
                        onChange={(e) => {
                          const updatedDiagnoses = [
                            ...editableData.diagnoses_with_treatment,
                          ];
                          updatedDiagnoses[index].treatment = e.target.value;
                          setEditableData({
                            ...editableData,
                            diagnoses_with_treatment: updatedDiagnoses,
                          });
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <ListItemText
                    primary={<strong>Diagnosis: {diag.diagnosis}</strong>}
                    secondary={
                      <Box component="span" sx={{ color: "black" }}>
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          Justification:
                        </Typography>
                        {" " + diag.justification}
                        <br />
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          Treatment:
                        </Typography>
                        {" " + diag.treatment}
                      </Box>
                    }
                  />
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1">No diagnoses selected</Typography>
        )}
      </Box>
      {isEditing && (
        <StandardButton text="Save Changes" handleClick={handleSave} />
      )}
      {!isEditing && userType === "vet" && (
        <>
          <Typography
            variant={isMobile ? "body" : "h6"}
            gutterBottom
            sx={{ marginTop: 4 }}
          >
            Email Report:
          </Typography>
          <Typography variant={isMobile ? "body2" : "body1"}>
            Enter email address to send the report to (separate multiple emails
            with a comma):
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: isMobile ? "100%" : "75%",
              marginRight: isMobile ? "0" : "600px",
            }}
          >
            <TextField
              label="example@example.com"
              multiline
              fullWidth
              sx={{ width: isMobile ? "100%" : "50%", marginRight: "800px" }}
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <StandardButton
              text="Email Report"
              handleClick={handleEmailReport}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
