export const fetchDifferentialApi = async (apiUrl, aptId, token) => {
  const response = await fetch(
    `${apiUrl}/api/v1/check-differential-list/${aptId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  );
  return response;
};

export const generateDifferentialsApi = async (apiUrl, aptId, token) => {
    const response = await fetch(
      `${apiUrl}/get-differentials/${aptId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    return response;
  };


