import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import StandardButton from "../components/StyledComponents/StandardButton";
import { useAuth0 } from '@auth0/auth0-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)",
}));

function Login({ theme }) {
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  // const [touched, setTouched] = useState({});
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const { loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (user) {
      console.log('User email:', user.email); // Send this to the backend.
    }
  }, [user]);

  // useEffect(() => {
  //   if (Object.values(touched).some((field) => field)) {
  //     setError("");
  //   }
  // }, [username, password, touched]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "username") setUsername(value);
  //   if (name === "password") setPassword(value);
  //   setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError("");
  //   setSuccess("");
  //   setTouched({});

  //   const data = {
  //     username: username,
  //     password: password,
  //   };

  //   try {
  //     const response = await fetch(`${apiUrl}/api/v1/login`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     const result = await response.json();

  //     if (response.ok) {
  //       // Store the JWT token in localStorage
  //       localStorage.setItem("token", result.access_token);
  //       setSuccess("Login successful");
  //       handleLogin();
  //       setTimeout(() => navigate("/appointments"), 1500);
  //     } else {
  //       setError(
  //         result.error || "Login failed. Please check your credentials."
  //       );
  //     }
  //   } catch (error) {
  //     setError("An error occurred. Please try again.");
  //   }
  // };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <StyledPaper elevation={3}>
          <Typography variant="h4" component="h1" gutterBottom>
            Login
          </Typography>
          {/* <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          > */}
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={handleChange}
            /> */}
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handleChange}
            /> */}
            <StandardButton text="Sign In" handleClick={() => loginWithRedirect()} />
          {/* </Box> */}
          {error && (
            <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
              {success}
            </Alert>
          )}
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
}

export default Login;
