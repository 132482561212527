import * as React from "react";
import SelectDiagnosis from "./SelectDiagnosis";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useMediaQuery, IconButton } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import StandardButton from "./StyledComponents/StandardButton";
import useTokenRedirect from "../utils/authUtils";
import { differentialRatingApi } from "../api/ratingsApi";
import { fetchDifferentialApi, generateDifferentialsApi } from "../api/differentialApi";

export default function DifferentialSelector({
  aptId,
  onDifferentialSubmit,
  theme,
}) {
  const [differentialList, setDifferentialList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [selectedRating, setSelectedRating] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getTokenOrRedirect } = useTokenRedirect();
  const token = getTokenOrRedirect();

  const fetchDifferentials = useCallback(async () => {
    try {
      const response = await fetchDifferentialApi(apiUrl, aptId, token)
      if (response.ok) {
        const data = await response.json();
        if (data.exists) {
          setDifferentialList(data.differentials);
        }
        setIsLoading(false);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching differentials:", error);
      setIsLoading(false);
    }
  }, [aptId, navigate, apiUrl]);

  useEffect(() => {
    fetchDifferentials();
  }, [fetchDifferentials]);

  const handleGenerateDifferentials = async () => {
    setIsGenerating(true);
    try {
      const response = await generateDifferentialsApi(apiUrl, aptId, token)
      if (response.ok) {
        const data = await response.json();
        setShowRating(true);
        setDifferentialList(data.differentials);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error generating differentials:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRatingClick = async (rating) => {
    setSelectedRating(rating);
    const ratingValue = rating;

    try {
      const response = await differentialRatingApi(apiUrl, aptId, ratingValue)
      if (response.ok) {
        const data = await response.json();
      } else {
        const errorData = await response.json();
        console.error("Failed to submit rating:", errorData);
      }
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  if (isLoading || isGenerating) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>
          {isGenerating ? "Generating AI Differentials" : "Loading..."}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {differentialList.length >= 1 ? (
        <>
          {showRating && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Typography variant="body1" style={{ marginRight: "10px" }}>
                Rate the Quality of the Response:
              </Typography>
              <IconButton
                onClick={() => handleRatingClick("up")}
                sx={{
                  color: selectedRating === "up" ? "#4CAF50" : "#9E9E9E",
                }}
              >
                <ThumbUpAltIcon fontSize="large" />
              </IconButton>
              <IconButton
                onClick={() => handleRatingClick("down")}
                sx={{
                  color: selectedRating === "down" ? "#F44336" : "#9E9E9E",
                }}
              >
                <ThumbDownAltIcon fontSize="large" />
              </IconButton>
            </div>
          )}
          <Typography
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
              color: "black",
              display: "inline-block",
              textTransform: "capitalize",
            }}
          >
            <strong>Patient Differentials:</strong>
          </Typography>
          <div>
            {differentialList?.map((differential, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Typography>{differential.diagnosis}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{differential.justification}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <Box sx={{ mt: 6 }}>
            <Typography
              variant="body1"
              sx={{
                mt: 2,
                fontWeight: "bold",
                color: "black",
                display: "inline-block",
                textTransform: "capitalize",
              }}
            >
              <strong>Select One or More: </strong>
            </Typography>
            <SelectDiagnosis
              differentialList={differentialList}
              aptId={aptId}
              onDifferentialSubmit={onDifferentialSubmit}
            />
          </Box>
          {isGenerating && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
              <Typography sx={{ mt: 2 }}>Generating Treatment Plan</Typography>
            </Box>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            mt: isMobile ? "75px" : "300px",
            mb: isMobile ? "75px" : "0px",
          }}
        >
          <StandardButton
            text="Generate AI Differentials"
            handleClick={handleGenerateDifferentials}
          />
        </Box>
      )}
    </Box>
  );
}
