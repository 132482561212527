export const summaryRatingApi = async (apiUrl, aptId, ratingValue) => {
  const response = await fetch(`${apiUrl}/api/add-summary-rating`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      appointment_id: aptId,
      rating: ratingValue,
    }),
  });
  return response;
};

export const differentialRatingApi = async (apiUrl, aptId, ratingValue) => {
    const response = await fetch(`${apiUrl}/api/add-differentials-rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        appointment_id: aptId,
        rating: ratingValue,
      }),
    });
    return response;
  };

  export const treatmentRatingApi = async (apiUrl, aptId, ratingValue) => {
    const response = await fetch(`${apiUrl}/api/add-treatment-rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        appointment_id: aptId,
        rating: ratingValue,
      }),
    });
    return response;
  };


