import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

function Logout() {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const { logout } = useAuth0();

  useEffect(() => {
    localStorage.removeItem("token");
    logout({ returnTo: window.location.origin });
    setIsLoggedOut(true);
  }, []);

  return (
    <div>
      <h1>{isLoggedOut ? "Logged out successfully" : "Logging out..."}</h1>
    </div>
  );
}

export default Logout;
