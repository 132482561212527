import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  useMediaQuery,
  IconButton,
} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import StandardButton from "./StyledComponents/StandardButton";
import useTokenRedirect from "../utils/authUtils";
import {
  checkMedicalSummary,
  generateSummaryApi,
  saveSummaryApi,
} from "../api/medicalSummaryApi";
import { summaryRatingApi } from "../api/ratingsApi";

export default function Summary({ aptId, notesSaved, theme, userType }) {
  const [aptSummary, setAptSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialSummaryGenerated, setInitialSummaryGenerated] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [selectedRating, setSelectedRating] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getTokenOrRedirect } = useTokenRedirect();
  const token = getTokenOrRedirect();

  const fetchSummary = useCallback(async () => {
    try {
      const response = await checkMedicalSummary(apiUrl, aptId, token);
      if (response.ok) {
        const data = await response.json();
        if (data.exists) {
          setAptSummary(data.summary);
        }
        setIsLoading(false);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching summary:", error);
      setIsLoading(false);
    }
  }, [aptId, navigate, apiUrl]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const handleGenerateSummary = async () => {
    setIsGenerating(true);
    try {
      const response = await generateSummaryApi(apiUrl, aptId, token);
      if (response.ok) {
        const data = await response.json();
        setAptSummary(data);
        setInitialSummaryGenerated(true);
        setShowRating(true);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error generating summary:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSaveSummary = async () => {
    try {
      const response = await saveSummaryApi(apiUrl, aptId, token, aptSummary);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setAptSummary(data);
        setIsEditing(false);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error saving summary:", error);
    }
  };

  const handleRatingClick = async (rating) => {
    setSelectedRating(rating);
    const ratingValue = rating;
    try {
      const response = await summaryRatingApi(apiUrl, aptId, ratingValue)
      if (response.ok) {
        const data = await response.json();
        console.log("Rating submitted successfully:", data);
      } else {
        const errorData = await response.json();
        console.error("Failed to submit rating:", errorData);
      }
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  const handleNavToDifferentials = () => {
    navigate(`/differentials/${aptId}`);
  };

  const formatSummary = (summary) => {
    // Remove ** from category headers
    let formattedSummary = summary
      ?.replace(/\*\*([^*]+)\:\*\*/g, "$1:") // Remove ** from headers
      .replace(/\*\*/g, "") // Remove any remaining **
      .replace(/\*\*([^*]+)\:\*\*\n/g, "$1:\n"); // Ensure category names are followed by a colon and newline

    // Remove # from the beginning of lines (used in markdown headings)
    formattedSummary = formattedSummary?.replace(/^#+\s+/gm, ""); // Remove any # symbols at the start of lines

    // Add indentation to list items
    formattedSummary = formattedSummary?.replace(
      /- /g,
      "&nbsp;&nbsp;&nbsp;&nbsp;- "
    );

    return formattedSummary;
  };

  const formattedSummary = formatSummary(aptSummary?.summary);

  if (isLoading || isGenerating) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>
          {isGenerating ? "Generating AI Summary" : "Loading..."}
        </Typography>
      </Box>
    );
  }

  return userType === "vet" ? (
    <Box>
      {aptSummary ? (
        <>
          <StandardButton
            text="Regnerate Summary"
            handleClick={handleGenerateSummary}
          />
          {showRating && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography variant="body1" style={{ marginRight: "10px" }}>
                Rate the Quality of the Response:
              </Typography>
              <IconButton
                onClick={() => handleRatingClick("up")}
                sx={{
                  color: selectedRating === 'up' ? '#4CAF50' : '#9E9E9E',
                }}
              >
                <ThumbUpAltIcon fontSize="large" />
              </IconButton>
              <IconButton
                onClick={() => handleRatingClick("down")}
                sx={{
                  color: selectedRating === 'down' ? '#F44336' : '#9E9E9E', 
                }}
              >
                <ThumbDownAltIcon fontSize="large" />
              </IconButton>
            </div>
          )}
          <Typography
            variant="h6"
            component="h2"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              textAlign: "left",
              mb: 2,
              mt: 1,
              color: "black",
              display: "inline-block",
              textTransform: "capitalize",
            }}
          >
            <strong>{aptSummary.patient_name}'s Medical Summary</strong>
          </Typography>

          {isEditing ? (
            <>
              <TextField
                label="Summary"
                fullWidth
                multiline
                sx={{ mb: 2 }}
                value={aptSummary.summary}
                onChange={(e) =>
                  setAptSummary({
                    ...aptSummary,
                    summary: e.target.value,
                  })
                }
              />
              <StandardButton
                text="Save Summary"
                handleClick={handleSaveSummary}
              />
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{ mb: 2 }}
                component="div"
                dangerouslySetInnerHTML={{
                  __html: formattedSummary?.replace(/\n/g, "<br />"),
                }}
              />
              <StandardButton
                text="Edit Summary"
                handleClick={() => setIsEditing(true)}
              />
              <Button
                variant="text"
                color="secondary"
                onClick={handleNavToDifferentials}
                sx={{
                  ml: 1,
                  justifyContent: "flex-start",
                  textAlign: "left",
                  textTransform: "capitalize",
                  "&:hover": {
                    color: "#0FBEB7",
                  },
                }}
              >
                Go to Differentials
              </Button>
            </>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: isMobile ? "75px" : "150px",
            mb: isMobile ? "75px" : "150px",
          }}
        >
          {notesSaved ? (
            <StandardButton
              text="Generate AI Summary"
              handleClick={handleGenerateSummary}
            />
          ) : (
            !notesSaved && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <Typography variant="body1" sx={{ mb: 2, color: "#0FBEB7" }}>
                  <strong>
                    Summary to go here after recording is submitted
                  </strong>
                </Typography>
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  ) : (
    <>
      <Typography
        variant="h6"
        component="h2"
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          textAlign: "left",
          mb: 2,
          color: "black",
          display: "inline-block",
          textTransform: "capitalize",
        }}
      >
        <strong>{aptSummary.patient_name}'s Medical Summary</strong>
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 2 }}
        component="div"
        dangerouslySetInnerHTML={{
          __html: formattedSummary?.replace(/\n/g, "<br />"),
        }}
      />
    </>
  );
}
